import React from 'react';
import './index.scss';
// 扩展
// import copy from 'copy-to-clipboard';
// import { isIOS, isAndroid } from 'react-device-detect';
import { isIos, isAndroid, inWeixin, copy, LaunchApp } from 'web-launch-app';

// 组件
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// 图片
import mobileModel from '../../static/images/mobile/mobile_model.png';
import logo from '../../static/images/mobile/logo.png';

function Mobile() {
  //#region 集成 openinstall
  // const script = document.createElement('script');
  // script.type = 'text/javascript';
  // script.src = '//web.cdn.openinstall.io/openinstall.js';
  // script.addEventListener('load', () => {
  //   // @ts-ignore
  //   const data = OpenInstall.parseUrlParams();
  //   // @ts-ignore
  //   new OpenInstall({
  //     //appkey参数配置,需要自行替换对应的appkey
  //     appKey: "xx1gfg",
  //     onready: function () {
  //       const that = this;
  //       const button = document.getElementById("download");
  //       that.schemeWakeup();
  //       if (button) {
  //         button.onclick = function () {
  //           that.wakeupOrInstall();
  //           return false;
  //         }
  //       }
  //     }
  //   }, data);
  // }, false);
  // document.head.appendChild(script);
  //#endregion

  const [show, setShow] = React.useState(false);

  function download() {
    if (inWeixin) {
      if (isIos)
        toast("请点右上角，在Safari浏览器中打开", { position: 'top-center' });
      else
        toast("请点右上角，在浏览器中打开", { position: 'top-center' });
    } else {
      const urlList = window.location.href.split('?');
      let scheme = 'xx1gfg://pages/home/home';

      if (urlList.length == 2) {
        copy(`hongliaobao install data ${urlList[1]}`);

        let baseData: { [key: string]: any } = {};
        let data = urlList[1].split('&').reduce((pre, item) => {
          const itemArray = item.split('=');
          pre[`${itemArray[0]}`] = itemArray[1];
          return pre;
        }, baseData);

        if (data.aid)
          scheme = `xx1gfg://pages/article_info/article_info?id=${data.aid}`;
        else if (data.uid)
          scheme = `xx1gfg://pages/author_info/author_info?id=${data.uid}`;
      }

      const lanchApp = new LaunchApp();
      lanchApp.open({
        launchType: {
          ios: 'scheme',
          android: 'scheme'
        },
        scheme,
        param: {
        },
        pkgs: {
          android: 'https://redliaobao.dashenliao.com/author/public/hongliaobao.APK',
          ios: 'https://apps.apple.com/us/app/%E7%BA%A2%E6%96%99%E5%AE%9D/id6480170592',
        }
      }, (s, d, url) => {
        /**
         * (s, d, url) => { return 0;} ，launchType为scheme或store方案时默认有超时逻辑，
         * 可通过设置tmieout为负值取消或根据callback中的返回值进行超时处理。
         * s表示唤起结果（0失败，1成功，2未知）, d为detector，url为最终的scheme或link值。
         * 无返回值默认下载apk包或去appstore，1不处理，2落地页，3应用市场（百度春晚活动时引导去应用市场下载分流减压）
         */
        if (s != 1) {
          url && copy(url);
          return 0;
        } else {
          return 1;
        }
      });
    }
  }

  function download2() {
    if (inWeixin) {
      if (isIos)
        toast("请点右上角，在Safari浏览器中打开", { position: 'top-center' });
      else
        toast("请点右上角，在浏览器中打开", { position: 'top-center' });
    } else {
      if (isIos)
        window.location.href = 'https://apps.apple.com/us/app/%E7%BA%A2%E6%96%99%E5%AE%9D/id6480170592';
      else
        window.location.href = 'https://redliaobao.dashenliao.com/author/public/hongliaobao.APK';
    }
  }

  function handleShow() {
    setShow(!show);
  }

  return (
    <div className='mobile'>
      <div className='title'>上红料宝 看体育资讯</div>
      <div className='tips'>大神分析, 智能大数据</div>
      <img className='logo' src={logo} />
      <div className='btn-box'>
        {/* <button id='download'>立即下载APP</button> */}
        <button onClick={download}>立即下载</button>
        <button onClick={download2}>备用下载</button>
      </div>
      <img className='mobile-model' src={mobileModel} />
      {/* 底部 */}
      {show ? <img className='customer-service' src="https://dashenliaohangzhou.oss-cn-hangzhou.aliyuncs.com/hongliaobaov_2//qianyue/20230212/167620922263e8ec46d247e.png" alt="联系客服" /> : null}
      <div className='internet-content-provider'>
        <a href='https://beian.miit.gov.cn/#/Integrated/index'>
          <img src="" />
          <span>湘ICP备2021001009号-3</span>
        </a>
        <span className='contacter' onClick={() => handleShow()}>联系我们</span>
      </div>
      {/* 提示 */}
      <ToastContainer />
    </div>
  );
}

export default Mobile;
