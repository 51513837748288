import React from 'react';
import './index.scss';
// 图片
import logo from '../../static/images/pc/cyhd.png';
import mobileModel from '../../static/images/pc/mobile_model.png';
import arrows from '../../static/images/pc/arrows.png';
import gxb from '../../static/images/gxb.png';

function PC() {
  const url = window.location.href;
  const QR_code_src = `https://www.dashenliao.com/user/public/index.php/api/qrcode/get_qrcode?url=${url}`;

  return (
    <div className='pc'>
      {/* 顶部 */}
      <div className='header'>
        <img className='logo min' src={logo} />
      </div>

      {/* 主体 */}
      <div className="content">
        {/* 手机 */}
        <img className='mobile-model' src={mobileModel} />
        {/* 左侧内容 */}
        <div>
          {/* 上部分 */}
          <div className="title">上红料宝 看体育资讯</div>
          <div className='introduce'>
            <div>比分直播，大神分析，智能大数据，AI赛果预测</div>
            <div>模拟走势，助您击落大奖!</div>
          </div>
          {/* 下部分 */}
          <div className='QR-code-box'>
            <div>
              <div className='guidance'>
                <div>扫描二维码下载红料宝</div>
                <div>大神分析, 应有尽有</div>
              </div>
              <div className='tips'>
                <span>iOS、Android 扫右边二维码下载</span>
                <img className='arrows' src={arrows} />
              </div>
            </div>
            <img className='QR_code' src={QR_code_src} />
          </div>
        </div>
      </div>

      {/* 底部 */}
      <div className='footer'>
        <div>
          <span>彩易互动网络科技有限公司</span>
          <span className='contacter'>联系我们</span>
          <img className='customer-service' src="https://dashenliaohangzhou.oss-cn-hangzhou.aliyuncs.com/hongliaobaov_2//qianyue/20230212/167620922263e8ec46d247e.png" alt="联系客服" />
        </div>
        {/* 备案 */}
        <a className='inline' href='https://beian.miit.gov.cn/#/Integrated/index'>
          <img className='icon' src={gxb} />
          <span>湘ICP备2021001009号-3</span>
        </a>
      </div>
    </div>
  );
}

export default PC;
