// 样式
import './App.scss';
// 组件
import PC from './components/pc';
import Mobile from './components/mobile';

function App() {
  const isMobile = () => {
    const regular = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regular.test(navigator.userAgent);
  }
  const isIOS = () => {
    const iosDevicesRegex = /iPad|iPhone|iPod/g;
    return iosDevicesRegex.test(navigator.userAgent);
  }

  return (
    <div className='App'>{isMobile() ? <Mobile /> : <PC />}</div>
  );
}

export default App;
